<template>
  <div class="page-box">
    <div class="custom-content">
      <a-spin :spinning="loading">
        <a href="javascript:;" class="btn-back" @click="$closeCurrentView">
          <icon-svg class="icon-back" icon-class="icon-back" />返回
        </a>
        <p class="order-info">
          <span>售后编号：{{serviceDetail.returnNo}}</span>
          <span>申请人：<i>{{serviceDetail.nickName}}</i></span>
          <span>申请时间：{{serviceDetail.createTime}}</span>
        </p>
        <div class="service-state">
          <p class="state-text">{{serviceDetail.stateText}}</p>
          <p class="info-info" v-if="serviceDetail.returnState === 0">请在 <a-statistic-countdown class="count-down" :value="serviceDetail.timeoutTime" format="D 天 H 小时 m 分钟 s 秒" @finish="getDetail" /> 内处理，逾期未处理，将自动退款给买家。</p>
          <p class="info-info" v-if="serviceDetail.returnState === -1">买家重新申请后，商家需要重新处理</p>
          <p class="info-info" v-if="serviceDetail.returnState === 2">买家需在 <a-statistic-countdown class="count-down" :value="serviceDetail.timeoutTime" format="D 天 H 小时 m 分钟 s 秒" @finish="getDetail" /> 内退货，逾期未处理，退款申请将自动撤销。</p>
          <p class="info-info" v-if="serviceDetail.returnState === 3">请在 <a-statistic-countdown class="count-down" :value="serviceDetail.timeoutTime" format="D 天 H 小时 m 分钟 s 秒" @finish="getDetail" /> 内确认收货，逾期未处理，将自动同意收货并退款给买家。</p>
        </div>
        <div class="tips" v-if="serviceDetail.returnState === -1">
          <p class="label">拒绝原因：</p>
          <p>{{serviceDetail.refusalCause}}</p>
        </div>
        <div class="detail-box">
          <a-row :gutter="16">
            <a-col class="col-item" :span="8">
              <p class="sub-title">售后商品</p>
              <div class="goods-info">
                <base-img
                  class="goods-img"
                  :src="serviceDetail.productSkuPic||serviceDetail.productMainPic"
                  width="60"
                  height="60"
                  oss_style="60_60"
                />
                <div class="goods-info-info">
                  <p class="name el2">{{serviceDetail.productName}}</p>
                  <p class="sku">商品规格：{{serviceDetail.productSkuName || '无'}}</p>
                </div>
              </div>
            </a-col>
            <a-col class="col-item" :span="8">
              <p class="sub-title">售后信息</p>
              <p class="detail-item">售后方式：{{refundTypeText}}</p>
              <p class="detail-item">退款金额：<span class="red">¥ {{serviceDetail.returnAmount}}</span>(含运费¥ {{serviceDetail.returnLogisticsAmount}})</p>
              <p class="detail-item">退款件数：{{serviceDetail.returnCount}}</p>
              <p class="detail-item">联系方式：{{serviceDetail.userPhone}}</p>
              <p class="detail-item">退款原因：{{serviceDetail.returnCause}}</p>
              <p class="detail-item">退款说明：{{serviceDetail.returnDescription||'无'}}</p>
              <p class="detail-item pic-list">凭证：<picture-card-upload v-if="serviceDetail.proofPics.length" :fileList="serviceDetail.proofPics" isPreview /><span v-else>无</span></p>
              <p class='detail-item' v-if="serviceDetail.checkTime">退款时间：{{serviceDetail.checkTime}}</p>
            </a-col>
            <a-col class="col-item" :span="8">
              <p class="sub-title">购买信息</p>
              <p class="detail-item">商品单价：<span class="red">¥ {{serviceDetail.price}}</span> x <span class="red">{{serviceDetail.count}}</span>件</p>
              <p class="detail-item">实付金额：<span class="red">¥ {{serviceDetail.subtotalAmount}}</span></p>
              <p class="detail-item">商品数量：{{serviceDetail.count}}</p>
              <p class="detail-item">发货状态：{{serviceDetail.deliveryState === 1 ? '已发货' : serviceDetail.orderType === 6 || serviceDetail.orderType === 7 || serviceDetail.orderType === 8 ? '无需发货': '未发货'}}</p>
              <p class="detail-item">订单编号：{{serviceDetail.orderNo}}</p>
            </a-col>
          </a-row>
        </div>
        <div class="logistics-info" v-if="serviceDetail.returnState === 3">
          <a-row>
            <a-col :span="9">
              <p class="info-item">物流公司：{{serviceDetail.logisticsCompName}}</p>
              <p class="info-item">物流单号：{{serviceDetail.logisticsNo}}</p>
              <p class="info-item">填写时间：{{serviceDetail.deliveryTime}}</p>
            </a-col>
            <a-col :span="15">
              <p class="info-item">物流状态：<span>{{handleDeliveryStatus(serviceDetail.logisticsTrack.deliverystatus)}}</span></p>
              <div class="track-list">
                <a-timeline v-if="trackList.length">
                  <a-timeline-item v-for="(item, i) in trackList" :key="`${item.time}${i}`">
                    {{item.status}}<br>{{item.time}}
                  </a-timeline-item>
                </a-timeline>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </div>
    <div class="footer" v-if="currentShopId === serviceDetail.supplyShopId && serviceDetail.returnState === 0">
      <a-button type="primary" @click="handleServiceFeedback('agree')">同意{{refundTypeText}}</a-button>
      <a-button @click="handleServiceFeedback('refuse')">拒绝{{refundTypeText}}</a-button>
    </div>
    <div class="footer" v-if="currentShopId === serviceDetail.supplyShopId && serviceDetail.returnState === 3">
      <a-button type="primary" @click="handleServiceFeedback('agree')">确认收货并退款</a-button>
      <a-button @click="handleServiceFeedback('refuse')">拒绝收货</a-button>
    </div>
    <!-- 拒绝弹框 -->
    <dialog-service-feedback
      :show.sync="dialogRefuseShow"
      :service-detail="serviceDetail"
      :operation-type="operationType"
      @success="getDetail"
    />
  </div>
</template>

<script>
import { order } from '@/api'
import { SHOPID } from '@/constants'
import { ref, computed } from '@vue/composition-api'
import { getSession } from '@/utils/session'
import { orderReturnStates, deliveryStatus } from '@/utils/constants'
import DialogServiceFeedback from './DialogServiceFeedback'

export default {
  name: 'PageServiceDetail',

  props: {
    shopOrderItemId: String
  },

  components: {
    DialogServiceFeedback
  },

  setup (props, { root }) {
    const loading = ref(false)
    const serviceDetail = ref({
      proofPics: []
    })
    const refundTypeText = computed(() => serviceDetail.value.returnMethod === 1 ? '仅退款' : '退货退款')
    const currentShopId = getSession(SHOPID)
    async function getDetail () {
      loading.value = true
      const { code, data } = await order.getServiceDetail(props.shopOrderItemId)
      loading.value = false
      if (code === '00000') {
        const matching = orderReturnStates.find(x => x.value === data.returnState)
        serviceDetail.value = {
          ...data,
          stateText: data.returnState === 1 ? `${data.returnMethod === 1 ? '退款' : '退货退款'}成功` : matching ? matching.name : '',
          proofPics: data.proofPics.split(',').filter(x => x)
        }
      } else {
        root.$message.warn('售后详情获取失败，请稍后重试')
        root.$closeCurrentView()
      }
    }
    getDetail()

    const dialogRefuseShow = ref(false)
    const operationType = ref('')
    function handleServiceFeedback (type) {
      operationType.value = type
      dialogRefuseShow.value = true
    }

    const trackList = computed(() => serviceDetail.value && serviceDetail.value.logisticsTrack && serviceDetail.value.logisticsTrack.list ? serviceDetail.value.logisticsTrack.list : [])
    function handleDeliveryStatus (value) {
      const matching = deliveryStatus.find(x => x.value === value)
      return matching ? matching.name : ''
    }

    return {
      loading,
      serviceDetail,
      refundTypeText,
      currentShopId,
      getDetail,
      dialogRefuseShow,
      operationType,
      handleServiceFeedback,
      trackList,
      handleDeliveryStatus
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  height: 100%;
  padding: 0;
  position: relative;
  font-size: 14px;
  color: #333;
  .custom-content {
    height: 100%;
    padding: 22px 20px 72px;
    overflow-y: auto;
  }
  .btn-back {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 32px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: 14px;
    color: #fff;
    background-color: #F39800;
    .icon-back {
      margin-right: 3px;
    }
  }
  .order-info span {
    margin-right: 34px;
    i {
      font-style: normal;
      color: #0066FF;
    }
  }
}
.service-state {
  margin-top: 24px;
  padding: 16px 20px 12px;
  border: 1px solid #DCDFE5;
  font-size: 14px;
  color: #666;
  line-height: 32px;
  .state-text {
    font-size: 20px;
    color: #333;
    line-height: 34px;
  }
  .info-info {
    display: flex;
    align-items: baseline;
    line-height: 28px;
    .count-down {
      margin: 0 3px;
      font-size: 20px;
      ::v-deep .ant-statistic-content {
        color: #F39800;
      }
    }
  }
  .btn-edit {
    color: #0066FF;
  }
}
.tips {
  display: flex;
  padding: 12px 18px;
  border: 1px solid #DCDFE5;
  border-top: none;
  line-height: 24px;
  .label {
    color: #F39800;
  }
}
.detail-box {
  margin-top: 20px;
  padding: 14px 20px;
  background-color: #F3F6F8;
  .sub-title {
    font-size: 14px;
    color: #333;
    line-height: 30px;
  }
  .goods-info {
    display: flex;
    padding-top: 8px;
    font-size: 14px;
    color: #666;
    .goods-img {
      flex: none;
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }
    .name {
      line-height: 20px;
    }
    .sku {
      font-size: 12px;
    }
  }
  .detail-item {
    font-size: 14px;
    color: #666;
    line-height: 28px;
  }
  .pic-list {
    display: flex;
  }
  .red {
    color: #E83828;
  }
}
.logistics-info {
  padding: 12px 20px;
  margin-top: 20px;
  border: 1px solid #DCDFE5;
  .info-item {
    font-size: 14px;
    color: #666;
    line-height: 28px;
    span {
      color: #F39800;
    }
  }
  .track-list {
    max-height: 260px;
    overflow-y: auto;
    padding-top: 12px;
  }
}
.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  box-shadow: 0 -1px 5px rgba(204, 204, 204, .75);
  background-color: #fff;
  ::v-deep .ant-btn {
    margin: 0 5px;
  }
}
</style>
